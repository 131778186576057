
					@import './src/sass/variables';
					@import './src/sass/mixins';
				































































































.admin-sidebar {
	h2 {
		font-size: 1.25em;
		line-height: $line__height;
		text-align: center;
		margin-bottom: $sidebar__padding;
	}

	&:not(.active) {
		nav {
			position: relative;
			top: 100px;
			padding: 0;

			ul {
				padding: 0;

				svg {
					margin: 0;
				}
			}
		}

		.sidebar-content a {
			justify-content: center;
		}
	}
}
